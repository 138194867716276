<template>
    <div class="footer-form py-5" :style="getBlockStyle">
        <div class="container text-center">
            <img src="~@/assets/icons/newsletter-form-icon.svg" alt="Girl in a jacket" width="50" />
            <form @submit.prevent="submitForm" class="row justify-content-center gy-4 gx-3 pt-3" novalidate>
                <div class="col-12 text-center fs-4">
                    Subscribe and get Exclusive Deals
                </div>
                <div class="col-xl-8">
                    <div class="row gy-4 gx-3 justify-content-center">
                        <div class="col-md col-9">
                            <input type="text" class="form-control input" placeholder="Enter your first name"
                                v-model.trim="form.firstName" required />
                            <div class="invalid-feedback">
                                Please type your first name
                            </div>
                        </div>
                        <div class="col-md col-9">
                            <input type="text" class="form-control input" placeholder="Enter your last name"
                                v-model.trim="form.lastName" required />
                            <div class="invalid-feedback">
                                Please type your last name
                            </div>
                        </div>
                        <div class="col-md col-9">
                            <input type="email" class="form-control input" placeholder="Enter your email"
                                v-model.trim="form.email" required />
                            <div class="invalid-feedback">
                                Please type your email
                            </div>
                        </div>
                        <div class="col-md col-9">
                            <input type="phone" class="form-control input" placeholder="Enter your phone number"
                                v-model.trim="form.phone" maxlength="10" required />
                            <div class="invalid-feedback">
                                Please type your phone number
                            </div>
                        </div>
                        <div class="col-md-auto col-7">
                            <button type="submit" class="btn btn-newsletter fw-400 w-100 btn-lg b" :style="getBtnStyle">SUBMIT</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <TheRandomIcon :type="4" />
    </div>
    <div class="footer py-5">
        <div class="container">
            <div class="row gy-4">
                <div class="col-md-6 col-lg-5 col-xl-4">
                    <div class="row gy-4" id="footerNavigation">
                        <div class="col-12">
                            <span class="fw-bold">Navigation</span>
                        </div>
                        <div class="col-lg-6 mt-0">
                            <ul>
                                <li>
                                    <router-link :to="{ name: 'home' }">
                                        Home
                                    </router-link>
                                </li>
                                <li>
                                    <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                        Vacation Packages
                                    </a>
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                        <li v-for="destination in websiteStore.destinations.slice(0, 5)"
                                            :key="destination.slug">
                                            <router-link class="dropdown-item" :to="{
        name: 'destination',
        params: { slug: destination.slug },
    }">
                                                {{ destination.name }}
                                            </router-link>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <router-link :to="{ name: 'reviews' }">
                                        Reviews
                                    </router-link>
                                </li>
                                <!-- <li>
                                  <router-link :to="{ name: 'reviews' }">
                                    Blog
                                  </router-link>
                                </li> -->
                                <li>
                                    <router-link :to="{ name: 'faqs' }">
                                        FAQs
                                    </router-link>
                                </li>
                                <li>
                                    <router-link :to="{ name: 'vip' }" v-if="websiteStore.isVip">
                                        VIP promotions
                                    </router-link>
                                    <span aria-current="page" data-bs-toggle="modal" data-bs-target="#vipModal" v-else>
                                        VIP promotions
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div class="col-lg-6 mt-0">
                            <ul>
                                <li>
                                    <router-link :to="{ name: 'terms' }">
                                        Terms & Conditions
                                    </router-link>
                                </li>
                                <li>
                                    <router-link :to="{ name: 'privacy' }">
                                        Privacy Policy
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-3 col-xl-4">
                    <p><b>Address</b></p>
                    <p>Boulevard Francisco Medina Ascencio KM 2.5, Zona Hotelera Norte,Puerto Vallarta, Jalisco.</p>
                    <p><b>Contact Us</b></p>
                    <a :href="'tel:+1' + websiteStore.getPhone">
                        <p>P: {{ websiteStore.getPhone }}</p>
                    </a>
                </div>
                <div class="col-lg-4 col-xl-4 pb-5 pb-lg-0">
                    <div class="row gy-2">
                        <div class="col-md-6 col-lg-12">
                            <div class="row gy-2">
                                <strong>Accepted Credit Cards</strong>
                                <img class="col-auto" src="@/assets/icons/cc-mastercard.svg" />
                                <img class="col-auto" src="@/assets/icons/cc-visa.svg" />
                                <img class="col-auto" src="@/assets/icons/cc-amex.svg" />
                                <img class="col-auto" src="@/assets/icons/cc-discover.svg" />
                                <div class="col-12">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-12">
                            <div class="row gy-2">
                                <b>Contact Us</b>
                                <a class="navbar-brand col-auto"
                                    href="https://www.instagram.com/vacationdealsallinclusive/" target="_blank"
                                    rel="noopener noreferrer"><img src="@/assets/icons/instagram_icon.svg" /></a>
                                <a class="navbar-brand col-auto"
                                    href="https://www.facebook.com/VacationDealsAllInclusive/" target="_blank"
                                    rel="noopener noreferrer"><img src="@/assets/icons/facebook_icon.svg" /></a>
                                <!-- <a class="navbar-brand col-auto" href="https://www.tiktok.com/@vacationdealsvdai" target="_blank"
                                  rel="noopener noreferrer"><img src="@/assets/icons/Tik_tok_white.svg" width="30" /></a> -->
                                <p class="pt-4">Costalegre Promomed SA de CV</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="fixed-bottom shadow-lg text-center row d-lg-none d-flex align-items-center">
        <div class="col-10 pt-3 pb-2 phone-number">
            <a :href="'tel:' + websiteStore.getPhone">
                <h3> Toll Free: {{ websiteStore.getPhone }}</h3>
            </a>
        </div>
        <div class="col-2 chat-icon ps-0 ">
            <button class="btn" @click="jivo_openChat()">
                <img src="@/assets/images/layout/chat_white.svg" alt="">
            </button>
        </div>
    </div>
    <!-- <TheStickMessage /> -->
    <!-- <TheStickMessageMobile /> -->
</template>

<script>
import { Swal2 } from '@/config/swal';

import { registerLead } from "@/api/eplat";
import { useWebsiteStore } from '@/store/website';
// import TheStickMessage from "@/components/TheStickMessage.vue"
// import TheStickMessageMobile from "@/components/TheStickMessageMobile.vue"

export default {
    setup() {
        const websiteStore = useWebsiteStore();

        return { websiteStore };
    },
    data() {
        return {
            form: {
                webhookID: "305979b4-ddfc-2ee4-fd91-32b9cf22b466",
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
                leadTypeID: 99,
                leadSourceChannelID: this.websiteStore.getChannel,
                leadSourceID: this.websiteStore.getSource,
                campaignName: this.websiteStore.getCampaign,
            },
        }
    },
    components: {
        // TheStickMessage,
        // TheStickMessageMobile
    },
    methods: {
        jivo_openChat() {
            console.log('passssssssss')
            /*eslint no-undef: "error"*/
            // eslint-disable-next-line
            jivo_api.open()
           
        },
        async submitForm({ target }) {

            if (target.checkValidity()) {
                this.loadingMessage();

                await registerLead(this.form)
                    .then(() => {
                        Swal2.close();
                        // console.log(res);
                        this.formEvent()
                        this.alertMessage()
                        this.tab = 1
                    })
                    .catch((error) => {
                        Swal2.close();
                        console.log(error);
                        this.errorMessage()
                        this.tab = 0
                    }).finally(() => {
                        //Perform action in always
                    });


            } else target.classList.add('was-validated');

        },
        formEvent() {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({ 'event': 'form_success', 'formname': 'suscribe_deals' });
        },
        errorMessage() {
            return Swal2.fire({
                icon: "warning",
                title: "Oops",
                text: "An unexpected error ocurred, please try again.",
            });
        },
        loadingMessage() {
            this.loading = Swal2.mixin({
                customClass: {
                    loader: 'spinner',
                }
            })
            return this.loading.fire({
                text: "Submitting",
                showConfirmButton: false,
                iconColor: "#0c9491",
                backdrop: `rgba(0,101,129,0.7)`,
                width: 160,
                timerProgressBar: true,
                // timer: 3000,
                didOpen: () => {
                    this.loading.showLoading();
                },
            });
        },
        alertMessage() {
            this.tab = 3
            return Swal2.fire({
                icon: "success",
                title: "Thanks, all ready set.",
                text: "An agent will be in touch with you soon.",
                color: "#0C9491",
                showConfirmButton: false,

                iconColor: "#0C9491",

            });
        },
        setBlockColor() {
      if(this.websiteStore.isCampaign) {
        this.blockCampaignStyle=this.websiteStore.campaign.blocksColor
      }
    },
    setBtnStyle() {
      if(this.websiteStore.isCampaign) {
        this.btnCampaignStyle=this.websiteStore.campaign.btnColors
      }
    }
    },
computed: {
    getBlockStyle() {
      this.setBlockColor()
      return this.blockCampaignStyle
    },
    getBtnStyle() {
      this.setBtnStyle()
      return this.btnCampaignStyle
    }
}
}

//var jivochatEn = document.createElement('script');
//jivochatEn.type = 'text/javascript';
//jivochatEn.src = '//code.jivosite.com/widget/g4zpIPbolV';

//var jivochatEs = document.createElement('script');
//jivochatEs.type = 'text/javascript';
//jivochatEs.src = '//code.jivosite.com/widget/fnYxFfMQb5';

//if (window.location.pathname.includes('/es')) {
//    document.head.appendChild(jivochatEs);
//} else {
//    document.head.appendChild(jivochatEn);
//}
</script>

<style scoped>
.footer {
    background-color: #0C9491;
    color: #ffff;
}


a {
    text-decoration: none;
    color: #ffff;
}

.footer-form {
    background-color: #3a2050;
    color: #ffff;
}

.input {
    border: 2px solid #0c9491;
    height: 48px;
}

li a {
    color: #0c9491
}

.fixed-bottom {
    background-color: #0c9491;
}

.nav-link {
    color: white;
    padding: 4px;
}

.chat-icon {
    background-color: #0c9491;
}

.phone-number {
    background-color: #0A6B69;
}

#footerNavigation ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}

#footerNavigation>div>ul>li>a {
    color: white;
}

a {
    transition: ease 0.25s text-shadow;
}

a:not(.dropdown-item):hover {
    text-shadow: 5px 5px 5px #0f6160;
}

#footerNavigation>div>ul>li {
    padding-top: 0.5rem;
}

#footerNavigation>div>ul>li {
    padding-bottom: 0.5rem;
}

.bg-campaign-02 {
    background-color: #023c61
}

.btn-newsletter {
    background: linear-gradient(63deg, rgba(234,38,43,1) 0%, rgba(188,11,18,1) 49%);
    color: white;
}
</style>
